import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import Form from 'components/design-system/forms/form'
import { Formik } from 'formik'
import { useRef } from 'react'

const ModalEditor = ({
  title,
  children,
  data,
  validationSchema,
  isLoading,
  isVisible,
  onClose,
  onSubmit,
  submitButtonText = null,
  isSaving = false,
  hasSaveButton = true,
  ...rest
}) => {
  const initialRef = useRef(null)
  const finalRef = useRef(null)

  const close = (formik) => {
    onClose(formik)
    formik.resetForm()
  }

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik) => (
        <Modal
          isOpen={isVisible}
          onClose={() => close(formik)}
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          size="lg"
          closeOnOverlayClick={false}
          {...rest}
        >
          <ModalOverlay />
          <ModalContent rounded={'none'}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
              <Form isLoading={isLoading}>
                <Stack mx="4">
                  <Box>
                    {children}

                    {formik.errors.general && (
                      <Box alignItems="center" color={'red.500'} mt={4} fontSize={'sm'}>
                        {formik.errors.general.toString()}
                      </Box>
                    )}
                  </Box>
                </Stack>
              </Form>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup spacing={2}>
                <Button onClick={() => close(formik)} disabled={isSaving}>
                  Cancel
                </Button>
                {hasSaveButton && (
                  <Button onClick={() => formik.handleSubmit()} disabled={isSaving} colorScheme="purple">
                    {submitButtonText || 'Save'}
                  </Button>
                )}
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalEditor
