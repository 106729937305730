const setPagination = (path, page, filter) => {
  path = path.replace('/', '')

  sessionStorage.setItem(`filters-${path}`, JSON.stringify(filter))
  sessionStorage.setItem(`page-index-${path}`, page.toString())
}

const removePagination = (path) => {
  path = path.replace('/', '')

  sessionStorage.removeItem(`filters-${path}`)
  sessionStorage.removeItem(`page-index-${path}`)
}

const getPaginationIndex = (path) => {
  path = path.replace('/', '')

  return sessionStorage.getItem(`page-index-${path}`) != null
    ? parseInt(sessionStorage.getItem(`page-index-${path}`))
    : 1
}

const getPaginationFilters = (path) => {
  path = path.replace('/', '')

  return sessionStorage.getItem(`filters-${path}`) != null
    ? JSON.parse(sessionStorage.getItem(`filters-${path}`))
    : null
}

export { setPagination, removePagination, getPaginationIndex, getPaginationFilters }
