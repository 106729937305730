import { Autocomplete } from 'components/design-system/forms'
import {
  ListSchoolsDocument,
  ListSchoolsQuery,
  ListSpecificSchoolsDocument,
  ListSpecificSchoolsQuery,
  useGetSchoolQuery
} from 'graphql/generated'
import { useState } from 'react'
import { useClient } from 'urql'

export function SchoolAutocomplete({
  label,
  name,
  filter = null,
  placeholder,
  helper,
  initialSchoolId = null,
  isRequired,
  withSpecificSchool = false,
  specificSchoolInitialValue = null,
  specificSchoolLabel = null,
  specificSchoolName = null,
  specificSchoolPlaceholder = null
}) {
  const client = useClient()
  const [schoolId, setSchoolId] = useState<Number>(initialSchoolId)
  const isSchoolQueryPaused = !initialSchoolId
  const [{ data }] = useGetSchoolQuery({
    pause: isSchoolQueryPaused,
    variables: { id: initialSchoolId }
  })
  const initialSchool = data?.school

  const onSearchSchool = async (text) => {
    if (text == '') {
      return []
    }

    const result = await client
      .query<ListSchoolsQuery>(ListSchoolsDocument, {
        name: text,
        first: 10,
        page: 1
      })
      .toPromise()

    //TODO: Create an error wrapper with a default, that uses toast to display an error
    return filter ? filter(result.data.schools.data) : result.data.schools.data
  }

  const onSpecificSchoolSearch = async (text) => {
    if (text == '') {
      return []
    }

    const result = await client
      .query<ListSpecificSchoolsQuery>(ListSpecificSchoolsDocument, {
        schoolDistrictId: +schoolId,
        name: text,
        first: 15,
        page: 1
      })
      .toPromise()

    return result.data.specificSchools.data
  }

  // TODO changing school does not clear specificSchool

  return (
    <>
      <Autocomplete
        label={label}
        name={name}
        placeholder={placeholder}
        initialValue={initialSchool}
        extractText={(item) => `${item.name} - ${item.slug}`}
        search={onSearchSchool}
        isRequired={isRequired}
        helper={helper}
        onChange={(val) => {
          setSchoolId(val.id)
        }}
      />

      {withSpecificSchool && (
        <Autocomplete
          disabled={!schoolId}
          label={specificSchoolLabel}
          name={specificSchoolName}
          placeholder={specificSchoolPlaceholder}
          initialValue={specificSchoolInitialValue}
          extractText={(item) => `${item.name}`}
          search={onSpecificSchoolSearch}
          isRequired={false}
          helper=""
        />
      )}
    </>
  )
}
