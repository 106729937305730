import { Box, Drawer, DrawerContent, Flex, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { getLesson, getLessonPath, getLessonTypes, newLessonValidationSchema } from '@heytutor/common'
import BasicDataEditor from 'components/common/basic-data-editor'
import { PopupFormHandler } from 'components/common/popup-form-handler'
import { Select } from 'components/design-system/forms'
import GlobalSpinner from 'components/global-spinner'
import LessonPathBasicDataEditor from 'components/lesson-paths/lesson-path-basic-data-editor'
import {
  LessonType,
  useCreateLessonMutation,
  useCreateLessonPathMutation,
  useCreateMetaTagMutation
} from 'graphql/generated'
import { usePageLoadingDetector } from 'hooks'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactNode, useState } from 'react'
import { getIsUsingNewLessonPathMode } from 'utils'
import routes from 'utils/routes'
import { MetatagForm } from '../design-system/metatags/form-modal'
import { useLoading } from './LoadingContext'
import MobileNav from './mobile-nav'
import SidebarContent from './sidebar-content'

export default function Layout({ children }: { children: ReactNode }) {
  const router = useRouter()
  const loadingContext = useLoading()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [metatag, _setMetatag] = useState({ id: '', name: '' })
  const lessonHandler = PopupFormHandler()
  const lessonPathHandler = PopupFormHandler()
  const metatagHandler = PopupFormHandler()
  const [_1, createLesson] = useCreateLessonMutation()
  const [_2, createLessonPath] = useCreateLessonPathMutation()
  const [_3, createMetatag] = useCreateMetaTagMutation()
  const [isSavingNewMetatag, setIsSavingNewMetatag] = useState(false)
  const [isSavingNewLesson, setIsSavingNewLesson] = useState(false)
  const [isSavingNewLessonPath, setIsSavingNewLessonPath] = useState(false)
  const isPageLoading = usePageLoadingDetector()

  const onSaveLesson = async (values, actions) => {
    setIsSavingNewLesson(true)
    loadingContext.withLoading(async () => {
      const metatagsData =
        values.metaTags.length > 0
          ? {
              metaTags: {
                sync: values.metaTags.map((x) => x.value)
              }
            }
          : {}
      //TODO: This can fail, we need to capture the exception and show
      // some notification telling the user that there was an error or show it in the same pop up form without closing it
      const lesson = {
        title: values.title,
        internalTitle: values.internalTitle,
        description: values.description,
        type: getIsUsingNewLessonPathMode() ? LessonType.Standard : values.type,
        levelId: values.level.id,
        subjectId: values.subject.id,
        ...metatagsData,
        published: values.published
      }
      //TODO: This can fail, we need to capture the exception in the top level and show
      // some notification telling the user that there was an error or show it in the same pop up form without closing it
      const result = await createLesson(lesson)

      if (!result.error) {
        lessonHandler.close(actions)
        setIsSavingNewLesson(false)
        router.push(routes.editLesson(result.data.createLesson.id))
      }

      setIsSavingNewLesson(false)
    })
  }

  const onSaveLessonPath = async (values, actions) => {
    setIsSavingNewLessonPath(true)
    loadingContext.withLoading(async () => {
      const metatagsData =
        values.metaTags.length > 0
          ? {
              metaTags: {
                sync: values.metaTags.map((x) => x.value)
              }
            }
          : {}
      const lessonPathDistricts = {
        create: [
          {
            districtId: values.school.id,
            schoolSiteId: values.specificSchool?.id
          }
        ]
      }
      //TODO: This can fail, we need to capture the exception and show
      // some notification telling the user that there was an error or show it in the same pop up form without closing it
      const lessonPath = {
        title: values.title,
        description: values.description,
        type: values.type,
        levelId: values.level.id,
        schoolId: values.school.id,
        specificSchoolId: values.specificSchool?.id,
        subjectId: values.subject?.id || undefined,
        published: values.published,
        districts: getIsUsingNewLessonPathMode() ? lessonPathDistricts : undefined,
        ...metatagsData
      }
      //TODO: This can fail, we need to capture the exception in the top level and show
      // some notification telling the user that there was an error or show it in the same pop up form without closing it
      const result = await createLessonPath(lessonPath)

      lessonPathHandler.close(actions)
      setIsSavingNewLessonPath(false)
      router.push(routes.editLessonPath(result.data.createLessonPath.id))
    })
  }

  const onSaveMetatag = async (values, actions) => {
    setIsSavingNewMetatag(true)
    loadingContext.withLoading(async () => {
      metatagHandler.close(actions)

      await createMetatag(values)
    })
    router.push(routes.metatags)
    setIsSavingNewMetatag(false)
  }

  return (
    <Flex direction={{ base: 'column', md: 'row' }} position="fixed" top="0" left="0" width="100%" height="100%">
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <style>{`
          html, body, #__next, #__next > div {
            font-size: 15px !important;
          }
        `}</style>
      </Head>

      <SidebarContent
        lessonHandler={lessonHandler}
        lessonPathHandler={lessonPathHandler}
        metatagHandler={metatagHandler}
        onClose={onClose}
        display={{ base: 'none', md: 'block' }}
        flexShrink={0}
      />

      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            lessonHandler={lessonHandler}
            lessonPathHandler={lessonPathHandler}
            metatagHandler={metatagHandler}
            onClose={onClose}
          />
        </DrawerContent>
      </Drawer>

      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />

      <Box flex={1} position="relative" overflow="auto" bg={useColorModeValue('gray.100', 'gray.900')}>
        {isPageLoading ? <GlobalSpinner /> : <Box padding="12">{children}</Box>}
      </Box>

      {lessonHandler.isOpen && (
        <BasicDataEditor
          title={'Create lesson'}
          data={getLesson()}
          validationSchema={newLessonValidationSchema}
          isVisible={lessonHandler.isOpen}
          isLoading={false}
          isSaving={isSavingNewLesson}
          withLevel={true}
          withSubject={true}
          withInternalTitle={true}
          onClose={(formik) => lessonHandler.close(formik)}
          onSubmit={onSaveLesson}
        >
          {!getIsUsingNewLessonPathMode() && (
            <>
              <Select
                label="Type"
                name="type"
                items={getLessonTypes()}
                placeholder="Choose Type"
                isRequired={true}
                marginBottom={'15px'}
              />
            </>
          )}
        </BasicDataEditor>
      )}

      {lessonPathHandler.isOpen && (
        <LessonPathBasicDataEditor
          title={'Create lesson path'}
          data={getLessonPath()}
          isNew={true}
          isLoading={false}
          isSaving={isSavingNewLessonPath}
          isOpen={lessonPathHandler.isOpen}
          onClose={(formik) => lessonPathHandler.close(formik)}
          onSubmit={onSaveLessonPath}
        />
      )}

      <MetatagForm
        isOpen={metatagHandler.isOpen}
        title={'Create metatag'}
        metatag={metatag}
        isSaving={isSavingNewMetatag}
        isLoading={false}
        onSave={onSaveMetatag}
        onCancel={metatagHandler.close}
      />
    </Flex>
  )
}
