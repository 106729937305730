import { Box } from '@chakra-ui/react'
import { lessonPathValidationSchema } from '@heytutor/common'
import BasicDataEditor from 'components/common/basic-data-editor'
import { SchoolAutocomplete } from 'components/design-system/school/school-autocomplete'
import { GetLessonPathByFiltersDocument, GetLessonPathByFiltersQuery } from 'graphql/generated'
import { useClient } from 'urql'
import { getIsUsingNewLessonPathMode } from 'utils'

function useDuplicateBackendValidation(validationSchema, data?) {
  const client = useClient()

  return validationSchema.test({
    name: 'backend-validation',
    test: async function (values) {
      if (!values.school?.id) {
        return true
      }

      const lessonPathResult = await client
        .query<GetLessonPathByFiltersQuery>(GetLessonPathByFiltersDocument, {
          levelId: values.level.id,
          schoolId: values.school.id,
          specificSchoolId: values?.specificSchool?.id || undefined,
          subjectId: values.subject?.id || undefined
        })
        .toPromise()

      if (lessonPathResult.error) {
        return this.createError({
          path: 'general',
          message: 'There was an error in our servers'
        })
      }

      const lessonPath = lessonPathResult.data.lessonPathByFilters

      if (!lessonPath || lessonPath.id == data?.id) {
        return true
      }

      return this.createError({
        path: 'general',
        message: 'There is already a lesson path with the same parameters'
      })
    }
  })
}

export default function LessonPathBasicDataEditor({
  title,
  data,
  isLoading,
  isSaving,
  isOpen,
  isNew = false,
  onClose,
  onSubmit
}) {
  const validationSchema = useDuplicateBackendValidation(lessonPathValidationSchema, data)

  return (
    <BasicDataEditor
      title={title}
      data={data}
      validationSchema={validationSchema}
      isVisible={isOpen}
      isLoading={isLoading}
      isSaving={isSaving}
      withLevel={true}
      withSubject={true}
      onClose={(formik) => onClose(formik)}
      onSubmit={(values, actions) => onSubmit(values, actions)}
      forLessonPath
    >
      {!getIsUsingNewLessonPathMode() && isNew && (
        <SchoolAutocomplete
          label="School"
          name="school.id"
          placeholder="Type school name"
          initialSchoolId={data?.school?.id}
          isRequired={false}
          helper=""
          withSpecificSchool={true}
          specificSchoolName="specificSchool.id"
          specificSchoolLabel="Specific School"
          specificSchoolPlaceholder="Type specific school name (optional) "
        />
      )}
      {!getIsUsingNewLessonPathMode() &&
        !isNew &&
        (data.specificSchool?.id ? (
          <Box sx={{ mt: 4, mb: 4 }}>
            <b>This LessonPath is for a specific school</b>
            <br />
            district and school edits not supported at this time, please contact eng team to assist
          </Box>
        ) : (
          <SchoolAutocomplete
            label="School"
            name="school.id"
            placeholder="Type school name"
            initialSchoolId={data?.school?.id}
            isRequired={false}
            helper=""
            withSpecificSchool={false}
          />
        ))}
    </BasicDataEditor>
  )
}
