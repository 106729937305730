import { metatagValidationSchema } from '@heytutor/common'
import React from 'react'
import ModalEditor from '../../common/modal-editor'
import { EditionForm } from './edition-form'

export const MetatagForm = ({ isOpen, title, metatag, isSaving, onSave, onCancel, isLoading }) => {
  return (
    isOpen && (
      <ModalEditor
        title={title}
        data={metatag}
        validationSchema={metatagValidationSchema}
        isLoading={isLoading}
        isSaving={isSaving}
        isVisible={isOpen}
        onClose={(formik) => onCancel(formik)}
        onSubmit={onSave}
        size={'5xl'}
      >
        <EditionForm />
      </ModalEditor>
    )
  )
}
