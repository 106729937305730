import { useDisclosure } from '@chakra-ui/react'

export function PopupFormHandler() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const open = () => {
    onOpen()
  }

  const close = (actions) => {
    onClose()

    setTimeout(() => {
      if (actions) {
        actions.resetForm()
      }
    }, 500)
  }

  return {
    isOpen,
    open,
    close
  }
}
